.blocoteste {
    width: 100%;
    background-color: red;
    height: 50px;
}
.nav-link{
    color: white;
}
.nav-link:hover {
    color:red !important
}

.containerminhaconta{
    
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.enderecoItem{
    text-align: center;
}

.titulominhaconta{
    font-size: 32px;
    text-align: center;
    width: 100%;
    margin-bottom: 30px
}

.titutousuario{
    font-size: 21px;
    font-weight: 400;
    
}

.butaotrocarsenhad {
    width: 200px;
    height: 30px;
    cursor: none;
    text-align: center;
    background-color: #c9c9c9;
    border-radius: 40px;
    color: #FFF;
    margin-bottom: 20px;
}

.butaotrocarsenha {
    cursor: pointer;
    width: 200px;
    height: 30px;
    text-align: center;
    background-color: #0E23DD;
    border-radius: 40px;
    color: #FFF;
    margin-bottom: 20px;
    
}

.containerdadosusuario{
    border: 1px solid  #0E23DD;
    display: flex;
    flex-direction: column; 
    padding: 5%;
    border-radius: 20px;
    margin-top: 10px;
    align-items: center;
}

.recSenhaMestre{
    display: flex;
    align-content: space-between;
    flex-direction: column;
}

.recSenha {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.RecSenhacasedadosusuario{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.RecSenhacasedadosusuario > span{
    margin-bottom: 5px;
    font-size: 14px;
}

.RecSenhacasedadosusuario > input {
    border-radius: 10px;
    width: 97%;
    margin-left: 2%;
    border: rgb(235, 73, 73) solid 2px;
}


.titulostabelaminhaconta{
    border-top: 2px solid  #0E23DD;
    border-bottom: 2px solid  #0E23DD;
    border-right: 2px solid  #0E23DD;
    text-align: center;
}

.titulostabelaminhaconta:first-child{
    border-left: 1px solid black;
}

.containerimg_spanminhaconta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px
}

.containerimg_spanminhaconta > img{
    max-height: 120px;

}

.titulo {
    font-size: 2em;
}

.contlistaprodutosminhaconta{
    display:flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid  #0E23DD;
}

.contlistaprodutosminhaconta > div {
    text-align: center;
}


.continerspinminhaconta{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.containerfooterminhaconta{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}