/* Dispositivos extra small (telefones em modo retrato, com menos de 576px)
 Sem media query para `xs`, já que este é o padrão, no Bootstrap.*/
 
/* .tudomesmo {
    background-image: url(../../images/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
 }*/



.logo { 
    max-height: 150px;
}

/* Dispositivos small (telefones em modo paisagem, com 576px ou mais)*/
@media (max-width: 576px) {
    div.row div a img {
        padding-right: 0 !important;
    }
}

/* Dispositivos médios (tablets com 768px ou mais)*/
@media (min-width: 768px)  and  (max-width:992px) {
    .tudomesmo {
        background-image: url(/static/media/bg.27fb3e8e.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }    

}

/* Dispositivos large (desktops com 992px ou mais)*/
@media (min-width: 992px) { 
    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 19.999999% !important;
        flex: 0 0 19.999999% !important;
        max-width: 19.999999% !important;
    }
}

/* Dispositivos extra large (desktops grandes com 1200px ou mais)*/
@media (min-width: 1200px) {

}